export const subproductoAgricola: string[] = [
  "aceites",
  "azucares",
  "bebidas",
  "carnicos",
  "cereal",
  "colorantes",
  "condimentos",
  "corteza",
  "granos",
  "enlatados",
  "fibras",
  "flores",
  "frutos",
  "gomas",
  "harinas",
  "hojas",
  "hortaliza",
  "hongos",
  "huevos",
  "lacteos",
  "legumbre",
  "licores",
  "miel",
  "panaderia",
  "raiz",
  "semillas",
];
