import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { productoTerminadoSub } from "src/app/models/productoTerminado";
import { subproductoEquipos } from "src/app/models/subproductoEquipos";
import { subproductoInsumos } from "src/app/models/subproductoInsumos";
import { subproductoNoMaderable } from "src/app/models/subproductoNoMaderable";
import { subproductoAgricola } from "src/app/models/subproductosAgricolas";
import { categoriasLista } from "src/assets/data/categorias";
import { paises } from "src/assets/data/paises";
import { FiltroProducto } from "../../models/FiltroProducto";
import { EspeciesService } from "src/app/services/especies.service";
import { ProductoService } from "src/app/services/producto.service";
import { Especie } from "src/app/models/especie.interface";
import { Subject, takeUntil, tap } from "rxjs";

@Component({
  selector: "app-filtro",
  templateUrl: "./filtro.component.html",
  styleUrls: ["./filtro.component.css"],
})
export class FiltroComponent implements OnInit, OnDestroy {
  private sub$: Subject<void> = new Subject();
  public listaProductoMobiliario: string[] = productoTerminadoSub;
  public listaProductoNoMaderable: string[] = subproductoNoMaderable;
  public listaProductoAgricola: string[] = subproductoAgricola;
  public listaProductoInsumos: string[] = subproductoInsumos;
  public listaProductoEquipos: string[] = subproductoEquipos;
  public categorias: string[] = categoriasLista;
  public listaDepartamentos: string[] = [];
  paises: any = paises;
  listaPaises: string[] = Object.keys(this.paises);
  especieOpciones: Especie[] = [];
  formFiltro!: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private especiesService: EspeciesService,
    private productoService: ProductoService
  ) {
    this.getEspecies();
  }

  ngOnInit(): void {
    this.initForm();
  }
  ngOnDestroy(): void {
    this.sub$.next();
    this.sub$.complete();
  }

  async getEspecies(): Promise<void> {
    try {
      this.especieOpciones = await this.especiesService.getAll();
    } catch (err) {
      console.error(err);
    }
  }

  initForm() {
    this.formFiltro = this.formBuilder.group({
      pais: "",
      departamento: "",
      especie: "",
      categoria: "",
      producto: "",
    } as FiltroProducto);

    const filtros = this.productoService.filtroProducto.getValue();
    this.formFiltro.patchValue(filtros);

    this.formFiltro.valueChanges
      .pipe(
        takeUntil(this.sub$),
        tap({
          next: (result: FiltroProducto) => {
            this.productoService.filtroProducto.next(result);
          },
          error: (err) => {
            console.log(err);
          },
        })
      )
      .subscribe();
  }

  onSelectPais(pais: string): void {
    if (this.paises[pais]) {
      this.listaDepartamentos = Object.keys(this.paises[pais]);
    }
  }
}
